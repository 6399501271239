import React from 'react'
import Helmet from 'react-helmet'
import {connect} from 'react-redux'
import PT from 'prop-types'
import RRPT from 'react-router-prop-types'
import imgAndroid from './images/android.png'
import imgGooglePlay from './images/google_play.png'
import imgIos from './images/ios.png'
import './index.scss'
import {
  iOSDownloadUrl4X,
  androidDownloadUrl4X,
  directDownloadUrl4X,
} from '../../constants/def'

class Home extends React.Component {
  state = {
    contactVisible: false,
  }

  componentDidMount() {}

  hideContactAlert = () => {
    this.setState({
      contactVisible: false,
    })
  }

  showContactAlert = () => {
    this.setState({
      contactVisible: true,
    })
  }

  getYear = () => new Date().getFullYear()

  render() {
    return (
      <div className="Home">
        <Helmet>
          <title>Welcome to Haza</title>
          <style>{`body{background-color: #F9F6FF;}`}</style>
        </Helmet>
        <div>
          <div className="Home__wrap">
            <div className="Home__main">
              <div className="Home__logo" />
              <div className="Home__app" />
              <div className="Home__welcome">Welcome to Haza</div>
              <pre className="Home__slogan1">Joyful, Fun, Energetic</pre>
              <div className="Home__downloadWrap">
                <div
                  role="button"
                  tabIndex="0"
                  onClick={() => {
                    window.location.href = iOSDownloadUrl4X
                  }}
                  className="Home__iOS"
                >
                  <img src={imgIos} alt="" width="23" height="26" />
                  AppStore
                </div>
                <div
                  role="button"
                  tabIndex="0"
                  onClick={() => {
                    window.location.href = androidDownloadUrl4X
                  }}
                  className="Home__android"
                >
                  <img src={imgGooglePlay} alt="" width="23" height="26" />
                  Google Play
                </div>
                <div
                  role="button"
                  tabIndex="0"
                  onClick={() => {
                    window.location.href = directDownloadUrl4X
                  }}
                  className="Home__android"
                >
                  <img src={imgAndroid} alt="" width="23" height="26" />
                  Android APK
                </div>
              </div>
            </div>
          </div>
          <div className="Home__footer">
            <div className="Home__footerBar">
              <a href="http://h5.hazaclub.com/hybrid/about/ts">
                User Agreement
              </a>
              <a href="http://h5.hazaclub.com/hybrid/about/pp">
                Privacy Policy
              </a>
              <div
                className="Home__contact"
                onMouseLeave={this.hideContactAlert}
                onMouseEnter={this.showContactAlert}
              >
                Contact Us
                {this.state.contactVisible && (
                  <div className="Home__contactWrap">
                    Mail：official@hazaclub.com<br />
                  </div>
                )}
              </div>
            </div>
            <div className="Home__companyInfo">
              {`TAALA PTE. LTD. · ${this.getYear()}`}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Home.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  history: RRPT.history.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  userAgent: PT.string.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  hostname: PT.string.isRequired,
}

Home.defaultProps = {}

export default connect(state => {
  const {
    env: {userAgent, hostname},
  } = state
  // console.log('state', state)
  return {userAgent, hostname}
})(Home)
